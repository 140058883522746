.expand-container {
  transition: max-height 5s ease;
  max-height: 1000em;
  /* display: inherit; */
}
.expand-hidden {
  max-height: 0em;
  /* Because max-height is so large, we need this to be shorter when closing */
  transition: max-height 0.125s ease;
  /* display: none; */
}
.expand-button-hidden {
  display: none;
}

@media screen and (min-width: 40em) {
  .md-text-columns > .md-block > p {
    columns: 2;
  }
}
@media screen and (min-width: 60em) {
  .md-text-columns > .md-block > p {
    columns: 1;
  }
}
@media screen and (min-width: 75em) {
  .md-text-columns > .md-block > p {
    columns: 2;
  }
}
