body {
  background: #cdecff;
}

.section {
  background-color: #f4f4f4;
  padding: 1rem 2rem;
}

.section:first-child, .section:nth-child(3) {
  background-color: #fff;
}

@media screen and (min-width: 60em) {
  .mainsections > .section {
    background-color: #f4f4f4;
    width: 50%;
    padding: 1.5rem 3rem;
  }

  .mainsections > .section:first-child, .section:last-child {
    background-color: #fff;
  }
}

.bg-light-blue {
  background-color: #64b3e9;
}

.clouds {
  z-index: -1;
  height: 200%;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.cloud {
  animation: 40s linear infinite cloud_floating;
  position: absolute;
}

.cloud.one {
  width: 30.125vw;
  max-width: 28rem;
  animation-duration: 120s;
  animation-delay: -80s;
  top: -7vw;
}

.cloud.two {
  animation-duration: 140;
  width: 38.375vw;
  max-width: 36rem;
  animation-delay: -10s;
  top: 5rem;
}

.cloud.three {
  width: 34.125vw;
  max-width: 32rem;
  animation-duration: 90s;
  animation-delay: -20s;
  top: 60vw;
}

@media screen and (min-width: 95em) {
  .cloud.three {
    top: 50rem;
  }
}

@keyframes cloud_floating {
  0% {
    left: -50%;
  }

  100% {
    left: 105%;
  }
}

.measure-wider {
  max-width: 40em;
}

.layout-text_page ol ol {
  list-style-type: lower-alpha;
}

.layout-text_page ol ol ol {
  list-style-type: lower-roman;
}

.layout-text_page ol ol ol ol {
  list-style-type: decimal;
}

.endorsements {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.endorsements > li {
  margin-right: 2rem;
}

.hover-color {
  filter: grayscale();
  opacity: .5;
  transition: filter .5s ease-out, transform .25s ease-out;
}

.hover-color:hover {
  filter: none;
  opacity: 1;
  transition: filter .5s ease-out, transform .25s ease-out;
}

.schedule {
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.schedule-item {
  flex: 20rem;
}

.expand-container {
  max-height: 1000em;
  transition: max-height 5s;
}

.expand-hidden {
  max-height: 0;
  transition: max-height .125s;
}

.expand-button-hidden {
  display: none;
}

@media screen and (min-width: 40em) {
  .md-text-columns > .md-block > p {
    columns: 2;
  }
}

@media screen and (min-width: 60em) {
  .md-text-columns > .md-block > p {
    columns: 1;
  }
}

@media screen and (min-width: 75em) {
  .md-text-columns > .md-block > p {
    columns: 2;
  }
}

/*# sourceMappingURL=index.84e5e292.css.map */
