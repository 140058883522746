body {
  background: #cdecff;
}

.mainsections {
  /* margin: 1rem; */
}

.section {
  padding: 1rem 2rem;
  background-color: #f4f4f4;
}

.section:first-child {
  background-color: #fff;
}

.section:nth-child(3) {
  background-color: #fff;
}

@media screen and (min-width: 60em) {
  .mainsections > .section {
    width: 50%;
    background-color: #f4f4f4;
    padding: 1.5rem 3rem;
  }
  .mainsections > .section:first-child,
  .section:last-child {
    background-color: #fff;
  }
}

.bg-light-blue {
  /* background-color: hsl(204, 75, 65); */
  background-color: rgb(100, 179, 233);
}

.clouds {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  height: 200%;
}

.cloud {
  position: absolute;
  animation: cloud_floating 40s linear infinite;
}

.cloud.one {
  animation-duration: 120s;
  animation-delay: -80s;
  top: -7vw;
  width: calc(241 * 1vw * 0.125);
  max-width: 28rem;
}

.cloud.two {
  top: 5rem;
  animation-duration: 140;
  animation-delay: -10s;
  width: calc(307 * 1vw * 0.125);
  max-width: 36rem;
}

.cloud.three {
  top: 60vw;
  animation-duration: 90s;
  animation-delay: -20s;
  width: calc(273 * 1vw * 0.125);
  max-width: 32rem;
}
@media screen and (min-width: 95em) {
  .cloud.three {
    top: 50rem;
  }
}

@keyframes cloud_floating {
  0% {
    left: -50%;
  }
  100% {
    left: 105%;
  }
}

.measure-wider {
  max-width: 40em;
}

.layout-text_page ol ol {
  list-style-type: lower-alpha;
}
.layout-text_page ol ol ol {
  list-style-type: lower-roman;
}
.layout-text_page ol ol ol ol {
  list-style-type: decimal;
}

.endorsements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.endorsements > li {
  margin-right: 2rem;
}

/* If we have _alot_ of endorsements */

/* .endorsements { */
  /* column-count: auto; */
  /* column-width: 15rem; */
  /* column-gap: 2rem; */
/* } */

.hover-color {
  filter: grayscale(100%);
  opacity: .5;
  transition: filter .5s ease-out, transform .25s ease-out;
}
.hover-color:hover {
  filter: none;
  opacity: 1;
  transition: filter .5s ease-out, transform .25s ease-out;
}

.schedule {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}
.schedule-item {
  flex: 1 1 auto;
  flex-basis: 20rem;
}
